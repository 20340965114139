import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { SinglePropertyResponse } from 'src/app/models/listing-model/single-property.model';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { PropertyProfileStatusRequest } from 'src/app/models/request-models/property-profile-status-request';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader-service/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ListingApiService {
  private apiUrl = environment.apiUrl;
  listOfProperties = new BehaviorSubject<SinglePropertyResponse[]>([]);
  isUserLoggedIn = new BehaviorSubject<boolean>(false);
  isMapUi = new BehaviorSubject<boolean>(false);
  currentSelectedCityInfo = new BehaviorSubject<any>({});
  currentActiveModel = new BehaviorSubject<string>('');
  isStreetViewVisible = new BehaviorSubject<{
    visible?: boolean;
    lat?: number;
    long?: number;
  }>({});
  constructor(private http: HttpClient, public loaderService: LoaderService) {}

  AddListing(request: FormData): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.apiUrl}${ApplicationApiUrls.AddListing}`, request)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  EditListing(request: FormData): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.apiUrl}${ApplicationApiUrls.AddListing}`, request)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
  GetListings(
    request: GetListingRequest
  ): Observable<BaseGridResponse<ListingResponse[]>> {
    this.loaderService.setLoading(true);
    delete request.pageNumber;
    delete request.pageSize;
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.GetListings}`, request)
      .pipe(
        map((data: BaseGridResponse<ListingResponse[]>) => {
          this.loaderService.setLoading(false);

          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetPropertyProfileById(request: number): Observable<SinglePropertyResponse> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetPropertyProfileById}`, {
        params: {
          id: request,
        },
      })
      .pipe(
        map((data: ApiResponse<SinglePropertyResponse>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
  SetPropertyProfileStatus(
    request: PropertyProfileStatusRequest
  ): Observable<SinglePropertyResponse> {
    return this.http
      .post(
        `${this.apiUrl}${ApplicationApiUrls.SetPropertyProfileStatus}`,
        request
      )
      .pipe(
        map((data: BaseGridResponse<SinglePropertyResponse>) => {
          return data.result;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
