import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader-service/loader.service';

@Injectable()
export class HttpDefaultInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoaderService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var lastSegment = req.url.split('/').pop()

    if(lastSegment != 'SendMessage') {
      this.loaderService.show();
    }

    const request = req.clone({
      setHeaders: {
        accept: 'application/json',
      },
    });

     return next.handle(req).pipe(
          finalize(() => {
            this.loaderService.hide();
          })
        );
  }
}
