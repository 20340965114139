import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Authorize } from '../models/authorize.model';
import {
  GetBlogRequest,
  GetPropertyRequest,
  Login,
  SetAdminEarnings,
  SetAllUser,
  SetAmenity,
  SetBlogList,
  SetBlog,
  SetBookingRequest,
  SetBookings,
  SetBookingSummaryChart,
  SetConversation,
  SetConversationList,
  SetDashBoardCards,
  SetIsLuxury,
  SetListing,
  SetMessage,
  SetPayouts,
  SetPropertyType,
  SetProviderEarnings,
  SetRevenueChart,
  SetReview,
  SetRoles,
  SetSingleBlog,
  SetSingleListing,
  SetTransaction,
  SetUser,
  SetUserIdForChat,
  SetUserProfile,
  SetWalletCards,
} from './snappstay.action';
import { PropertyType } from '../models/lookup/property-type';
import { Amenity } from '../models/listing-model/amenity';
import { ListingResponse } from '../models/listing-model/listing-response.model';
import { Role } from '../models/role.model';
import { UserRegestrationResponse } from '../models/response-model/user-regestration-response-model';
import { SinglePropertyResponse } from '../models/listing-model/single-property.model';
import { Review } from '../models/listing-model/review';
import { UserProfile } from '../models/user-profile.model';
import { GetListingRequest } from '../models/request-models/get-listing.request';
import { BaseGridResponse } from '../models/response-model/base-grid-response';
import {
  BookingSummaryChart,
  DashBoardCards,
  RevenueChart,
  WalletCards,
} from '../models/dashboard-cards';
import { BookingRequest } from '../models/request-models/booking-request';
import { Booking } from '../models/booking-model';
import { UserDetails } from '../models/users-details.model';
import { Payout } from '../models/payout.model';
import { SalesTransactions } from '../models/sales-transactions';
import { ProviderEarnings } from '../models/provider-earnings.model';
import { Message } from '../models/message';
import { UserConversation } from '../models/user-conversation';
import { UserChats } from '../models/user-chats';
import { AdminEarnings } from '../models/admin-earnings.model';
import { GetAllBlogsResponse } from '../models/response-model/blog-response';
import { SingleBlogResponse } from '../models/single-blog.model';
export class SnappstayStateModel {
  authorize: Authorize | undefined;
  propertyType: PropertyType[] | undefined;
  amenity: Amenity[] | undefined;
  listings: BaseGridResponse<ListingResponse[]> | undefined;
  blogs: BaseGridResponse<SingleBlogResponse[]> | undefined;
  singleListing: SinglePropertyResponse | undefined;
  singleBlog: SingleBlogResponse | undefined;
  roles: Role[] | undefined;
  user: UserRegestrationResponse | undefined;
  review: Review[] | undefined;
  userProfile: UserProfile | undefined;
  getListingRequest: GetListingRequest | undefined;
  getBlogRequest: GetListingRequest | undefined;
  dashBoardCards: DashBoardCards[] | undefined;
  walletCards: WalletCards[] | undefined;
  blogsList: GetAllBlogsResponse[] | undefined;
  revenueChart: RevenueChart[] | undefined;
  bookingSummaryChart: BookingSummaryChart[] | undefined;
  bookingRequest: BookingRequest | undefined;
  bookings: BaseGridResponse<Booking[]> | undefined;
  isLuxury: boolean | undefined;
  allUser: BaseGridResponse<UserDetails[]> | undefined;
  payout: Payout[] | undefined;
  transaction: SalesTransactions[] | undefined;
  providerEarnings: BaseGridResponse<ProviderEarnings[]> | undefined;
  adminEarnings: BaseGridResponse<AdminEarnings[]> | undefined;
  message: Message | undefined;
  userId: number | undefined;
  userConversation: UserConversation | undefined;
  userChats: UserChats[] | undefined;
}

const defaults = {
  authorize: undefined,
  propertyType: undefined,
  amenity: undefined,
  listings: undefined,
  blogs: undefined,
  singleListing: undefined,
  singleBlog: undefined,
  roles: undefined,
  user: undefined,
  review: undefined,
  userProfile: undefined,
  getListingRequest: undefined,
  getBlogRequest: undefined,
  dashBoardCards: undefined,
  walletCards: undefined,
  revenueChart: undefined,
  bookingSummaryChart: undefined,
  bookingRequest: undefined,
  bookings: undefined,
  isLuxury: undefined,
  allUser: undefined,
  payout: undefined,
  transaction: undefined,
  providerEarnings: undefined,
  adminEarnings: undefined,
  message: undefined,
  userId: undefined,
  userConversation: undefined,
  userChats: undefined,
  blogsList: undefined,
};

@State<SnappstayStateModel>({
  name: 'SnappstayState',
  defaults,
})
@Injectable()
export class SnappstayState {
  @Action(Login)
  login(ctx: StateContext<SnappstayStateModel>, { payload }: Login) {
    ctx.setState({ ...ctx.getState(), authorize: payload });
  }

  @Selector()
  static Authorize(state: SnappstayStateModel) {
    return state?.authorize;
  }

  @Action(SetPropertyType)
  SetPropertyType(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetPropertyType
  ) {
    ctx.setState({ ...ctx.getState(), propertyType: payload });
  }

  @Selector()
  static PropertyType(state: SnappstayStateModel) {
    return state.propertyType;
  }

  @Action(SetAmenity)
  SetAmenity(ctx: StateContext<SnappstayStateModel>, { payload }: SetAmenity) {
    ctx.setState({ ...ctx.getState(), amenity: payload });
  }

  @Selector()
  static Amenities(state: SnappstayStateModel) {
    return state.amenity;
  }

  @Action(SetListing)
  SetListing(ctx: StateContext<SnappstayStateModel>, { payload }: SetListing) {
    ctx.setState({ ...ctx.getState(), listings: payload });
  }

  @Selector()
  static Listings(state: SnappstayStateModel) {
    return state?.listings;
  }

  @Action(SetBlog)
  SetBlog(ctx: StateContext<SnappstayStateModel>, { payload }: SetBlog) {
    ctx.setState({ ...ctx.getState(), blogs: payload });
  }

  @Selector()
  static Blogs(state: SnappstayStateModel) {
    return state?.listings;
  }

  @Action(SetSingleListing)
  SetSingleListing(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetSingleListing
  ) {
    ctx.setState({ ...ctx.getState(), singleListing: payload });
  }

  @Selector()
  static SingleListing(state: SnappstayStateModel) {
    return state?.singleListing;
  }

  @Action(SetSingleBlog)
  SetSingleBlog(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetSingleBlog
  ) {
    ctx.setState({ ...ctx.getState(), singleBlog: payload });
  }

  @Selector()
  static SingleBlog(state: SnappstayStateModel) {
    return state?.singleBlog;
  }

  @Action(SetRoles)
  SetRoles(ctx: StateContext<SnappstayStateModel>, { payload }: SetRoles) {
    ctx.setState({ ...ctx.getState(), roles: payload });
  }

  @Selector()
  static GetRoles(state: SnappstayStateModel) {
    return state.roles;
  }

  @Action(SetUser)
  SetUser(ctx: StateContext<SnappstayStateModel>, { payload }: SetUser) {
    ctx.setState({ ...ctx.getState(), user: payload });
  }

  @Selector()
  static GetUser(state: SnappstayStateModel) {
    return state.user;
  }

  @Action(SetReview)
  SetReview(ctx: StateContext<SnappstayStateModel>, { payload }: SetReview) {
    ctx.setState({ ...ctx.getState(), review: payload });
  }

  @Selector()
  static GetReview(state: SnappstayStateModel) {
    return state.review;
  }

  @Action(SetUserProfile)
  SetUserProfile(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetUserProfile
  ) {
    ctx.setState({ ...ctx.getState(), userProfile: payload });
  }

  @Selector()
  static GetUserProfile(state: SnappstayStateModel) {
    return state?.userProfile;
  }

  @Action(GetPropertyRequest)
  GetListingRequest(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: GetPropertyRequest
  ) {
    ctx.setState({ ...ctx.getState(), getListingRequest: payload });
  }

  @Selector()
  static GetListingRequest(state: SnappstayStateModel) {
    return state.getListingRequest;
  }

  @Action(GetBlogRequest)
  GetBLogRequest(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: GetPropertyRequest
  ) {
    ctx.setState({ ...ctx.getState(), getListingRequest: payload });
  }

  @Selector()
  static GetBLogRequest(state: SnappstayStateModel) {
    return state.getListingRequest;
  }

  @Action(SetDashBoardCards)
  SetDashBoardCards(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetDashBoardCards
  ) {
    ctx.setState({ ...ctx.getState(), dashBoardCards: payload });
  }

  @Action(SetWalletCards)
  SetWalletCards(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetWalletCards
  ) {
    ctx.setState({ ...ctx.getState(), walletCards: payload });
  }

  @Action(SetBlogList)
  SetBlogList(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetBlogList
  ) {
    ctx.setState({ ...ctx.getState(), blogsList: payload });
  }

  @Action(SetRevenueChart)
  SetRevenueChart(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetRevenueChart
  ) {
    ctx.setState({ ...ctx.getState(), revenueChart: payload });
  }

  @Action(SetBookingSummaryChart)
  SetBookingSummaryChart(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetBookingSummaryChart
  ) {
    ctx.setState({ ...ctx.getState(), bookingSummaryChart: payload });
  }

  @Selector()
  static GetDashBoardCards(state: SnappstayStateModel) {
    return state.dashBoardCards;
  }

  @Selector()
  static GetWalletCards(state: SnappstayStateModel) {
    return state.walletCards;
  }

  @Selector()
  static GetBlogsList(state: SnappstayStateModel) {
    return state.blogsList;
  }

  @Selector()
  static GetRevenueChart(state: SnappstayStateModel) {
    return state.revenueChart;
  }

  @Selector()
  static GetBookingSummaryChart(state: SnappstayStateModel) {
    return state.bookingSummaryChart;
  }

  @Action(SetBookingRequest)
  SetBookingRequest(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetBookingRequest
  ) {
    ctx.setState({ ...ctx.getState(), bookingRequest: payload });
  }

  @Selector()
  static GetBookingRequest(state: SnappstayStateModel) {
    return state.bookingRequest;
  }

  @Action(SetBookings)
  SetBookings(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetBookings
  ) {
    ctx.setState({ ...ctx.getState(), bookings: payload });
  }

  @Selector()
  static Bookings(state: SnappstayStateModel) {
    return state.bookings;
  }

  @Action(SetIsLuxury)
  SetIsLuxury(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetIsLuxury
  ) {
    ctx.setState({ ...ctx.getState(), isLuxury: payload });
  }

  @Selector()
  static IsLuxury(state: SnappstayStateModel) {
    return state?.isLuxury;
  }

  @Action(SetAllUser)
  SetAllUser(ctx: StateContext<SnappstayStateModel>, { payload }: SetAllUser) {
    ctx.setState({ ...ctx.getState(), allUser: payload });
  }

  @Selector()
  static AllUser(state: SnappstayStateModel) {
    return state.allUser;
  }

  @Action(SetPayouts)
  SetPayouts(ctx: StateContext<SnappstayStateModel>, { payload }: SetPayouts) {
    ctx.setState({ ...ctx.getState(), payout: payload });
  }

  @Selector()
  static GetPayouts(state: SnappstayStateModel) {
    return state.payout;
  }

  @Action(SetTransaction)
  SetTransaction(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetTransaction
  ) {
    ctx.setState({ ...ctx.getState(), transaction: payload });
  }

  @Selector()
  static GetTransaction(state: SnappstayStateModel) {
    return state.transaction;
  }

  @Action(SetProviderEarnings)
  SetProviderEarnings(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetProviderEarnings
  ) {
    ctx.setState({ ...ctx.getState(), providerEarnings: payload });
  }

  @Selector()
  static GetProviderEarnings(state: SnappstayStateModel) {
    return state.providerEarnings;
  }

  @Action(SetAdminEarnings)
  SetAdminEarnings(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetAdminEarnings
  ) {
    ctx.setState({ ...ctx.getState(), adminEarnings: payload });
  }

  @Selector()
  static GetAdminEarnings(state: SnappstayStateModel) {
    return state.adminEarnings;
  }

  @Action(SetMessage)
  SetMessage(ctx: StateContext<SnappstayStateModel>, { payload }: SetMessage) {
    ctx.setState({ ...ctx.getState(), message: payload });
  }

  @Selector()
  static GetMessage(state: SnappstayStateModel) {
    return state.message;
  }

  @Action(SetUserIdForChat)
  SetUserIdForChat(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetUserIdForChat
  ) {
    ctx.setState({ ...ctx.getState(), userId: payload });
  }

  @Selector()
  static GetUserIdForChat(state: SnappstayStateModel) {
    return state.userId;
  }

  @Action(SetConversation)
  SetConversation(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetConversation
  ) {
    ctx.setState({ ...ctx.getState(), userConversation: payload });
  }

  @Selector()
  static GetConversation(state: SnappstayStateModel) {
    return state.userConversation;
  }

  @Action(SetConversationList)
  SetConversationList(
    ctx: StateContext<SnappstayStateModel>,
    { payload }: SetConversationList
  ) {
    ctx.setState({ ...ctx.getState(), userChats: payload });
  }

  @Selector()
  static GetConversationList(state: SnappstayStateModel) {
    return state.userChats;
  }
}
