import { Routes } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { SnappstayState } from './state/snappstay.state';
import { importProvidersFrom } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { HttpDefaultInterceptor } from './utils/interceptor/default.interceptor';
import { LoadingInterceptor } from './utils/interceptor/loading.service';
import { StripeSuccessComponent } from './components/common/stripe-success/stripe-success.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/pages/home-demo-two/home-demo-two.component').then(
        (m) => m.HomeDemoTwoComponent
      ),
    providers: [
      importProvidersFrom([NgxsModule.forFeature([SnappstayState])]),
      provideHttpClient(withInterceptorsFromDi()),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpDefaultInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true,
      },
    ],
  },
  {
    path: 'index-2',
    loadComponent: () =>
      import('./components/pages/home-demo-one/home-demo-one.component').then(
        (m) => m.HomeDemoOneComponent
      ),
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./components/pages/about-us/about-us.component').then(
        (m) => m.AboutUsComponent
      ),
  },
  {
    path: 'term-of-services',
    loadComponent: () =>
      import(
        './components/pages/terms-of-service/terms-of-service.component'
      ).then((m) => m.TermsOfServiceComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./components/pages/privacy-policy/privacy-policy.component').then(
        (m) => m.PrivacyPolicyComponent
      ),
  },
  {
    path: 'how-it-works',
    loadComponent: () =>
      import(
        './components/pages/how-it-works-page/how-it-works-page.component'
      ).then((m) => m.HowItWorksPageComponent),
  },
  {
    path: 'cart',
    loadComponent: () =>
      import('./components/pages/cart/cart.component').then(
        (m) => m.CartComponent
      ),
  },
  {
    path: 'checkout',
    loadComponent: () =>
      import('./components/pages/checkout/checkout.component').then(
        (m) => m.CheckoutComponent
      ),
  },
  {
    path: 'user-profile/:Id',
    loadComponent: () =>
      import('./components/pages/author-profile/author-profile.component').then(
        (m) => m.AuthorProfileComponent
      ),
  },
  {
    path: 'update-profile',
    loadComponent: () =>
      import(
        './components/pages/update-user-profile/update-user-profile.component'
      ).then((m) => m.UpdateUserProfileComponent),
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import(
        './components/pages/update-password/update-password.component'
      ).then((m) => m.UpdatePasswordComponent),
  },
  {
    path: 'categories',
    loadComponent: () =>
      import('./components/pages/categories/categories.component').then(
        (m) => m.CategoriesComponent
      ),
  },
  {
    path: 'single-listings/:id',
    loadComponent: () =>
      import(
        './components/pages/listings-details/listings-details.component'
      ).then((m) => m.ListingsDetailsComponent),
  },
  {
    path: 'blog-details/:id',
    loadComponent: () =>
      import('./components/pages/blog-details/blog-details.component').then(
        (m) => m.BlogDetailsComponent
      ),
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./components/pages/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
  },
  {
    path: 'dashboard-messages',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-messages/dashboard-messages.component'
      ).then((m) => m.DashboardMessagesComponent),
  },
  {
    path: 'dashboard-bookings',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component'
      ).then((m) => m.DashboardBookingsComponent),
  },
  {
    path: 'dashboard-wallet',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-wallet/dashboard-wallet.component'
      ).then((m) => m.DashboardWalletComponent),
  },
  {
    path: 'dashboard-reviews',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component'
      ).then((m) => m.DashboardReviewsComponent),
  },
  {
    path: 'dashboard-invoice/:id',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component'
      ).then((m) => m.DashboardInvoiceComponent),
  },
  {
    path: 'dashboard-my-profile',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component'
      ).then((m) => m.DashboardMyProfileComponent),
  },
  {
    path: 'dashboard-add-listings',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component'
      ).then((m) => m.DashboardAddListingsComponent),
  },
  {
    path: 'dashboard-add-listings/:id',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component'
      ).then((m) => m.DashboardAddListingsComponent),
  },
  {
    path: 'dashboard-bookmarks',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component'
      ).then((m) => m.DashboardBookmarksComponent),
  },
  {
    path: 'dashboard-my-listings/:status',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-my-listings/dashboard-my-listings.component'
      ).then((m) => m.DashboardMyListingsComponent),
  },
  {
    path: 'users-listing/:user',
    loadComponent: () =>
      import(
        './components/pages/dashboard/users-listing/users-listing.component'
      ).then((m) => m.UsersListingComponent),
  },
  {
    path: 'stripe-success',
    loadComponent: () =>
      import(
        './components/common/stripe-success/stripe-success.component'
      ).then((m) => m.StripeSuccessComponent),
  },
  {
    path: 'stripe-cancel',
    loadComponent: () =>
      import('./components/common/stripe-cancel/stripe-cancel.component').then(
        (m) => m.StripeCancelComponent
      ),
  },
  {
    path: 'paypal-success',
    loadComponent: () =>
      import(
        './components/common/paypal-success/paypal-success.component'
      ).then((m) => m.PaypalSuccessComponent),
  },
  {
    path: 'paypal-cancel',
    loadComponent: () =>
      import('./components/common/paypal-cancel/paypal-cancel.component').then(
        (m) => m.PaypalCancelComponent
      ),
  },
  {
    path: 'payouts',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-payouts/dashboard-payouts.component'
      ).then((m) => m.DashboardPayoutsComponent),
  },
  {
    path: 'sales-Transactions',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-sales-transactions/dashboard-sales-transactions.component'
      ).then((m) => m.DashboardSalesTransactionsComponent),
  },
  {
    path: 'admin-earnings',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-admin-earnings/dashboard-admin-earnings.component'
      ).then((m) => m.DashboardAdminEarningsComponent),
  },
  {
    path: 'user-earnings',
    loadComponent: () =>
      import(
        './components/pages/dashboard/dashboard-user-earnings/dashboard-user-earnings.component'
      ).then((m) => m.DashboardUserEarningsComponent),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./components/pages/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
  },
  {
    path: 'delete-account',
    loadComponent: () =>
      import('./components/pages/delete-account/delete-account.component').then(
        (m) => m.DeleteAccountComponent
      ),
  },
  {
    path: 'dashboard-add-blog',
    loadComponent: () =>
      import('./components/pages/dashboard/dashboard-add-blog/dashboard-add-blog.component').then(
        (m) => m.DashboardAddBlogComponent
      ),
  },
  {
    path: 'dashboard-add-blog/:id',
    loadComponent: () =>
      import('./components/pages/dashboard/dashboard-add-blog/dashboard-add-blog.component').then(
        (m) => m.DashboardAddBlogComponent
      ),
  },
  {
    path: 'dashboard-blogs-listing',
    loadComponent: () =>
      import('./components/pages/dashboard/dashboard-blogs/dashboard-blogs.component').then(
        (m) => m.DashboardBlogsComponent
      ),
  },
  {
    path: 'career-and-investors',
    loadComponent: () =>
      import(
        './components/pages/career-and-investors/career-and-investors.component'
      ).then((m) => m.CareerAndInvestorsComponent),
  },
  {
    path: 'blogs-list',
    loadComponent: () =>
      import('./components/pages/blogs-list/blogs-list.component').then(
        (m) => m.BlogsListComponent
      ),
  },
  {
    path: 'blogs-list/:id',
    loadComponent: () =>
      import('./components/pages/blogs-list/blogs-list.component').then(
        (m) => m.BlogsListComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  }, // This line will remain down from the whole pages component list
];
