import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
  RouterLinkWithHref,
  RouterOutlet,
} from '@angular/router';
import {
  CommonModule,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLinkWithHref,
    LoaderComponent,
    NavbarStyleTwoComponent,
    FooterStyleTwoComponent,
  ],
  standalone: true,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  title = 'Snappstay';
  location: any;
  routerSubscription: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  ngOnDestroy(): void {}
  ngAfterViewChecked(): void {}

  ngOnInit() {
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
