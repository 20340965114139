import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, catchError, throwError } from 'rxjs';
import { Authorize } from 'src/app/models/authorize.model';
import { Login } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router) {}

  private isRefreshing = false;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authorize = this.store.selectSnapshot<Authorize | undefined>(
      SnappstayState.Authorize
    );
    const isAuthenticated = authorize && authorize.token;

    if (isAuthenticated) {
      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authorize!.token}`,
        },
      });

      return next.handle(request).pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.logout();
          }

          return throwError(() => error);
        })
      );
    }

    return next.handle(req);
  }

  logout() {
    // Unauthorized error, redirect to login page
    this.store.dispatch(new Login(undefined));
    this.store.reset({});
    this.router.navigate(['/']).then();
  }
}
