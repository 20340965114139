export enum ApplicationApiUrls {
  // Authentication
  Login = '/Auth/login',
  ChangeExpiredPassword = '/Auth/ChangeExpiredPassword',
  AppleLogin = '/Auth/AppleLogin',
  GoogleLogin = '/Auth/GoogleLogin',
  TwoStepVerification = '/Auth/TwoStepVerification',
  ResetPassword = '/Auth/ResetPassword',
  ForgotPassword = '/Auth/ForgotPassword',
  DeleteAccount = '/Auth/DeleteAccount',

  // Account Management
  CreateUser = '/Account/CreateUser',
  GetMyProfile = '/Account/GetMyProfile',
  UpdateProfile = '/Account/UpdateProfile',
  UpdateProfileImage = '/Account/UpdateProfileImage',
  switchRole = '/Auth/SwitchRole',

  // Lookups
  GetAmenities = '/Lookup/get-all-amenities',
  GetPropertyTypes = '/Lookup/get-property-types',
  GetAllRoles = '/Lookup/get-roles',

  // Property Profile Management
  AddListing = '/PropertyProfile/CreatePropertyProfile',
  EditListing = '/PropertyProfile/CreatePropertyProfile',
  GetListings = '/PropertyProfile/GetAllPropertyProfileByAdvanceSearch',
  GetPropertyProfileById = '/PropertyProfile/GetPropertyProfileById',
  SetPropertyProfileStatus = '/PropertyProfile/SetPropertyProfileStatus',

  //blogs
  AddUpdateBlog = '/Blog/CreateUpdateBlog',
  GetBlogs = '/Blog/GetAllBlogs',
  GetBlogById = '/Blog/GetBlog',
  DeleteBlog = '/Blog/DeleteBlog',

  // Reviews
  GetReviewByPropertyProfileId = '/Review/GetReviewByPropertyProfileId',
  GetAllReview = '/Review/GetAllReview',
  CreateReview = '/Review/CreateReview',

  // Dashboard
  DashBoardCardsForAdmin = '/DashBoard/DashBoardCardsForAdmin',
  DashBoardCardsForHost = '/DashBoard/DashBoardCardsForHost',
  AdminDashBoardCard = '/Admin/AdminDashBoardCard',
  AdminDashBoardBookingCard = '/Admin/AdminDashBoardBookingCard',
  AdminRevenueChart = '/Admin/GetAdminRevenue',
  AdminBookingSummaryChart = '/Admin/BookingSummary',
  HostRevenueChart = '/DashBoard/Mobile-Host-GetRevenue?type=monthly',

  // Booking Management
  BookingReservation = '/Booking/BookingReservation',
  GetAllMyBooking = '/Booking/GetAllUserBooking',
  GetUnavailableDates = '/Booking/GetUnavailableDates',
  GetAllOwnerPropertyProfileBooking = '/Booking/GetAllOwnerPropertyProfileBooking',
  GetAllAdminBooking = '/Admin/GetAllAdminBooking',
  GetAllHostBooking = '/Booking/GetAllHostBooking',

  // User Management
  GetAllUser = '/Admin/GetAllUser',

  // Payouts
  GetAllPayout = '/Payouts/GetAllPayout',
  CreatePayout = '/Payouts/CreatePayout',
  GetPayoutById = '/Payouts/GetPayoutById',
  ChangePayoutStatus = '/Payouts/ChangePayoutStatus',
  GetAllAdminPayout = '/Payouts/GetAllAdminPayout',
  DeletePayoutById = '/Payouts/DeleteById',

  // Wallet Management
  GetWalletInfo = '/Wallet/GetWalletInfo',
  GetAllSalesTransactions = '/Wallet/GetAllSalesTransactions',
  GetAllProviderSale = '/Wallet/GetAllProviderSale',
  GetAllProviderEarnings = '/Wallet/GetAllProviderEarnings',
  GetAllAdminEarnings = '/Wallet/GetAllAdminEarnings',
  HostRemainingWalletBalance = '/Wallet/HostRemainingWalletBalance',
  WalletHostCard = '/Wallet/WalletHostCard',
  GetAllProviderEarningById = 'Wallet/GetInvoiceById?id=',

  // User Bank Account Management
  CreateUpdateBankInfo = '/UserBankAccount/CreateUpdateBankInfo',
  GetUserBankInfo = '/UserBankAccount/GetUserBankInfo',

  //Career and Investors
  ContactUs = '/ContactUs/ContactUs',

  // Blog
  GetAllBlogs = '/Blog/GetAllBlogs',
}
