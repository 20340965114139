import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader-service/loader.service';
import { OrderService } from 'src/app/services/common-services/order.service';
import { ListingApiService } from 'src/app/services/api-services/listing-api';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private listApiService: ListingApiService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const showLoader = !req.url.includes('/SendMessage');
    if (this.listApiService.isMapUi?.value) {
      this.loaderService.hide();
    } else if(showLoader){
      this.loaderService.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (showLoader) {
          this.loaderService.hide();
        }
      })
    );
  }
}
