import { AdminEarnings } from '../models/admin-earnings.model';
import { Authorize } from '../models/authorize.model';
import { Booking } from '../models/booking-model';
import {
  BookingSummaryChart,
  DashBoardCards,
  RevenueChart,
  WalletCards,
} from '../models/dashboard-cards';
import { Amenity } from '../models/listing-model/amenity';
import { ListingResponse } from '../models/listing-model/listing-response.model';
import { Review } from '../models/listing-model/review';
import { SinglePropertyResponse } from '../models/listing-model/single-property.model';
import { PropertyType } from '../models/lookup/property-type';
import { Message } from '../models/message';
import { Payout } from '../models/payout.model';
import { ProviderEarnings } from '../models/provider-earnings.model';
import { BookingRequest } from '../models/request-models/booking-request';
import { GetListingRequest } from '../models/request-models/get-listing.request';
import { BaseGridResponse } from '../models/response-model/base-grid-response';
import { GetAllBlogsResponse } from '../models/response-model/blog-response';
import { UserRegestrationResponse } from '../models/response-model/user-regestration-response-model';
import { Role } from '../models/role.model';
import { SalesTransactions } from '../models/sales-transactions';
import { SingleBlogResponse } from '../models/single-blog.model';
import { UserChats } from '../models/user-chats';
import { UserConversation } from '../models/user-conversation';
import { UserProfile } from '../models/user-profile.model';
import { UserDetails } from '../models/users-details.model';

export class Login {
  static readonly type = '[Snappstay state] Set Authorize user';

  constructor(public payload: Authorize | undefined) {}
}

export class SetPropertyType {
  static readonly type = '[Snappstay state] Set Property Type';

  constructor(public payload: PropertyType[] | undefined) {}
}

export class SetAmenity {
  static readonly type = '[Snappstay state] Set Amenity';

  constructor(public payload: Amenity[] | undefined) {}
}

export class SetListing {
  static readonly type = '[Snappstay state] Set Listing';

  constructor(
    public payload: BaseGridResponse<ListingResponse[]> | undefined
  ) {}
}

export class SetBlog {
  static readonly type = '[Snappstay state] Set Blog';

  constructor(
    public payload: BaseGridResponse<SingleBlogResponse[]> | undefined
  ) {}
}

export class SetSingleListing {
  static readonly type = '[Snappstay state] Set Single Listing';

  constructor(public payload: SinglePropertyResponse | undefined) {}
}

export class SetSingleBlog {
  static readonly type = '[Snappstay state] Set Single Blog';

  constructor(public payload: SingleBlogResponse | undefined) {}
}

export class SetRoles {
  static readonly type = '[Snappstay state] Set Roles';

  constructor(public payload: Role[] | undefined) {}
}

export class SetUser {
  static readonly type = '[Snappstay state] Set User';

  constructor(public payload: UserRegestrationResponse | undefined) {}
}

export class SetReview {
  static readonly type = '[Snappstay state] Set Review';

  constructor(public payload: Review[] | undefined) {}
}

export class SetUserProfile {
  static readonly type = '[Snappstay state] Set User Profile';

  constructor(public payload: UserProfile | undefined) {}
}

export class GetPropertyRequest {
  static readonly type = '[Snappstay state] Get Listing Request';

  constructor(public payload: GetListingRequest | undefined) {}
}

export class SetDashBoardCards {
  static readonly type = '[Snappstay state] Get DashBoard Cards';

  constructor(public payload: DashBoardCards[] | undefined) {}
}

export class SetWalletCards {
  static readonly type = '[Snappstay state] Get Wallet Cards';

  constructor(public payload: WalletCards[] | undefined) {}
}

export class SetRevenueChart {
  static readonly type = '[Snappstay state] Get Revenue Chart';

  constructor(public payload: RevenueChart[] | undefined) {}
}

export class SetBookingSummaryChart {
  static readonly type = '[Snappstay state] Get Booking Summary Chart';

  constructor(public payload: BookingSummaryChart[] | undefined) {}
}

export class SetBookingRequest {
  static readonly type = '[Snappstay state] Set Booking Request';

  constructor(public payload: BookingRequest | undefined) {}
}

export class SetBookings {
  static readonly type = '[Snappstay state] Set Bookings';

  constructor(public payload: BaseGridResponse<Booking[]> | undefined) {}
}

export class SetIsLuxury {
  static readonly type = '[Snappstay state] Set IsLuxury';

  constructor(public payload: boolean | undefined) {}
}

export class SetAllUser {
  static readonly type = '[Snappstay state] Set AllUser';

  constructor(public payload: BaseGridResponse<UserDetails[]> | undefined) {}
}
export class SetPayouts {
  static readonly type = '[Snappstay state] SetPayouts';

  constructor(public payload: Payout[] | undefined) {}
}

export class SetTransaction {
  static readonly type = '[Snappstay state] Set Transaction';

  constructor(public payload: SalesTransactions[] | undefined) {}
}

export class SetProviderEarnings {
  static readonly type = '[Snappstay state] Set Provider Earnings';

  constructor(
    public payload: BaseGridResponse<ProviderEarnings[]> | undefined
  ) {}
}

export class SetAdminEarnings {
  static readonly type = '[Snappstay state] Set Admin Earnings';

  constructor(public payload: BaseGridResponse<AdminEarnings[]> | undefined) {}
}

export class SetMessage {
  static readonly type = '[Snappstay state] Set Message';

  constructor(public payload: Message | undefined) {}
}

export class SetUserIdForChat {
  static readonly type = '[Snappstay state] Set User Id ForChat';

  constructor(public payload: number | undefined) {}
}

export class SetConversation {
  static readonly type = '[Snappstay state] Set Conversation';

  constructor(public payload: UserConversation | undefined) {}
}

export class SetConversationList {
  static readonly type = '[Snappstay state] Set Conversation List ';

  constructor(public payload: UserChats[] | undefined) {}
}

export class SetBlogList {
  static readonly type = '[Snappstay state] Set Blogs List';

  constructor(public payload: GetAllBlogsResponse[] | undefined) {}
}

export class GetBlogRequest {
  static readonly type = '[Snappstay state] Get Listing Request';

  constructor(public payload: GetListingRequest | undefined) {}
}
